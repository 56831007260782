<template>
    <footer>
        <p>&copy; 2024 Shopping List App</p>
    </footer>
</template>

<script>
export default {
    name: 'FooterComponent',
}
</script>

<style scoped>
footer {
    text-align: center;
    padding: 1rem;
    background-color: #f1f1f1;
}
</style>
